<template>
    <div class="flex gap-4 mb-4 justify-between w-full flex-col min-[750px]:flex-row">
        <div class="flex gap-4 min-[750px]:mb-4 items-center justify-between">
            <div>
                <VueDatePicker
                    v-model="dateSelectorDay"
                    :format="format"
                    :clearable="false"
                    :enable-time-picker="false"
                    :key="dateSelectorDay"
                    auto-apply
                    :min-date="DateTime.now().toJSDate()"
                    :max-date="DateTime.now().plus({days: 7}).toJSDate()"
                />
            </div>
            <div class="flex gap-2 items-center w-max">
                <PrimaryButton @click="changeDate( -1)">
                    <i class="fa-solid fa-chevron-left p-0.5"/>
                </PrimaryButton>
                |
                <PrimaryButton @click="changeDate( 1)">
                    <i class="fa-solid fa-chevron-right p-0.5"/>
                </PrimaryButton>
            </div>
        </div>
        <div class="flex gap-4 sm:mb-4 items-center justify-between">
            <select
                class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-max p-2.5 pr-8"
                v-model="screenWidthViewSingleSpace">
                <option :value="-1">Select a table</option>
                <option v-for="(space, index) in spaces" :value="index">{{ space.name }}</option>
            </select>

            <div class="flex gap-2 items-center w-max"
                 v-if="getSpacesInView.length !== spaces.length && screenWidthViewSingleSpace === -1">
                <PrimaryButton @click="changeStartingSpace(-1)">
                    <i class="fa-solid fa-chevron-left p-0.5"/>
                </PrimaryButton>
                |
                <PrimaryButton @click="changeStartingSpace(1)">
                    <i class="fa-solid fa-chevron-right p-0.5"/>
                </PrimaryButton>
            </div>
        </div>
    </div>

    <div class="calendar w-full"
         :style="{'grid-template-rows': 'min-content repeat('+ defineHours.length +', 1fr)'}">
        <div class="calendar-cell opacity-0 ">Time</div>
        <div class="calendar-cell w-[6rem]" v-for="hour in defineHours">{{ hour.visual }}</div>
        <template v-for="column in getSpacesInView">
            <div class="calendar-cell">{{ column.space.name }}</div>
            <div v-for="hour in buildOutDay(column)"
                 class="calendar-cell font-bold justify-center items-center"
                 :class="{
                     'bg-gray-400 rounded-lg shadow m-2 cursor-not-allowed' : typeof hour !== 'number' && hour !== 'disabled' && !hour.event_id,
                     'bg-gray-400 rounded-lg shadow m-2 cursor-pointer' : typeof hour === 'object' && hour.event_id,
                     'bg-primary hover:bg-primary-500': hour && hour !== 'disabled' && hour.type === 'event' && hour.private === 0,
                     'cursor-pointer hover:bg-gray-200' : typeof hour === 'number',
                     'unbookable-slot cursor-not-allowed' : hour === 'disabled',
                 }"
                 :style="{
                    'grid-row': (hour)  ? 'span ' + hour.hours :  'span 1'
                }"
                 @click="(typeof hour === 'number') ? $emit('purchaseBlock', $event, hour, column.space) : checkIfEvent(hour)"
            >
                <template v-if="hour && hour.name">
                    {{ hour.name || '' }}
                </template>
            </div>
        </template>
    </div>
</template>

<script setup>
    import {DateTime}                                     from "luxon";
    import {computed, onMounted, onUnmounted, ref, watch} from "vue";
    import VueDatePicker                                  from "@vuepic/vue-datepicker";
    import PrimaryButton                                  from "../InertiaComponents/PrimaryButton.vue";
    import {getDateFromISO}                               from "../Helpers/DateHelper.js";
    import {router}                                       from "@inertiajs/vue3";

    const props = defineProps({
        defineHours         : Object,
        currentDay          : Object,
        computedCalendarView: Object,
        spaces              : Object,
        events              : Object
    });

    const $emits = defineEmits(['changeCurrentDay', 'purchaseBlock'])

    const dateSelectorDay = ref(props.currentDay.toJSDate())

    //
    // MOBILE SUPPORT
    //

    const screenWidthViewSingleSpace = ref(-1)
    const screenWidthSelectedStartSpace = ref(0)
    const screenWidth = ref(window.innerWidth)

    const reformatScreenWidth = () => {
        screenWidthViewSingleSpace.value = -1
        screenWidthSelectedStartSpace.value = 0
        screenWidth.value = window.innerWidth
    }

    onMounted(() => {
        window.addEventListener('resize', reformatScreenWidth);
    })

    onUnmounted(() => {
        window.removeEventListener('resize', reformatScreenWidth)
    })

    watch(screenWidthViewSingleSpace, (e) => {
        if (e === -1) {
            screenWidthSelectedStartSpace.value = 0
        }
    })

    watch(dateSelectorDay, () => {
        $emits('changeCurrentDay', DateTime.fromJSDate(dateSelectorDay.value));
    })

    const changeDate = (amount) => {
        let currentDay = props.currentDay
        currentDay = currentDay.plus({days: amount})

        const tempDate = (currentDay).toUnixInteger()
        const today = (DateTime.now().plus({day: -1})).toUnixInteger()
        const aWeekFromNow = (DateTime.now().plus({day: 7})).toUnixInteger()

        if (tempDate >= today && tempDate <= aWeekFromNow) {
            dateSelectorDay.value = currentDay.toJSDate();
        }
    }

    const getSpacesInView = computed(() => {
        if (screenWidthViewSingleSpace.value > -1
            && props.computedCalendarView[screenWidthViewSingleSpace.value]) {
            return [
                props.computedCalendarView[screenWidthViewSingleSpace.value]
            ]
        }

        if (screenWidth.value <= 649) {
            return props.computedCalendarView.slice(screenWidthSelectedStartSpace.value, 1 + screenWidthSelectedStartSpace.value)
        } else if (screenWidth.value >= 650 && screenWidth.value <= 849) {
            return props.computedCalendarView.slice(screenWidthSelectedStartSpace.value, 2 + screenWidthSelectedStartSpace.value)
        } else if (screenWidth.value >= 850 && screenWidth.value <= 1099) {
            return props.computedCalendarView.slice(screenWidthSelectedStartSpace.value, 3 + screenWidthSelectedStartSpace.value)
        } else if (screenWidth.value >= 1100 && screenWidth.value <= 1400) {
            return props.computedCalendarView.slice(screenWidthSelectedStartSpace.value, 4 + screenWidthSelectedStartSpace.value)
        }

        return props.computedCalendarView.slice(screenWidthSelectedStartSpace.value, 6 + screenWidthSelectedStartSpace.value)
    })

    const changeStartingSpace = (amount) => {
        const tempAmount = screenWidthSelectedStartSpace.value + amount

        if (tempAmount >= 0 && tempAmount <= props.spaces.length - getSpacesInView.value.length) {
            screenWidthSelectedStartSpace.value = tempAmount
        }
    }


    const buildOutDay = (space) => {
        const hours = [];
        const nowMinusHour = DateTime.now().plus({hour: 1})

        for (let i = 0; i < props.defineHours.length; i++) {
            const dateObj = props.currentDay.set({
                'hour'  : props.defineHours[i].time,
                'minute': 0
            })
            const date = dateObj.toFormat('yyyy-MM-dd HH:00')

            if (space.calendar[date]) {
                if (space.calendar[date].type === 'event') {
                    const event = props.events[space.space.id].find((e) => {
                        return e.event_id === space.calendar[date].id
                    })

                    event.date = dateObj.toISO()

                    if (event) {
                        hours.push({...event, type: "event"})
                        i += event.hours - 1
                    } else {
                        hours.push(null)
                    }
                } else {
                    hours.push({
                        "type" : "order",
                        "hours": space.calendar[date].number_of_slots,
                        "name" : "Booked"
                    })
                    i += space.calendar[date].number_of_slots - 1
                }
            } else if (dateObj <= nowMinusHour) {
                hours.push("disabled")
            } else {
                hours.push(i + 10)
            }
        }

        for (let i in hours) {
            if (hours[i] && hours[i].hours && hours[i].type && hours[i].type === "event") {
                if (hours && hours.length >= 1) {
                    const time = getDateFromISO(hours[i].date).set({minute: 0, millisecond: 0})
                    const endTime = DateTime.now().set({
                        hour       : props.defineHours[props.defineHours.length - 1].time,
                        minute     : 0,
                        millisecond: 0
                    })
                    let numHours = (endTime.hour - time.hour) + 1
                    hours[i].hours = hours[i].hours < numHours ? hours[i].hours : numHours
                }
            }
        }

        return hours
    }

    const format = (date) => {
        return DateTime.fromJSDate(date).toLocaleString(DateTime.DATE_MED_WITH_WEEKDAY);
    }

    const checkIfEvent = (hour) => {
        if (typeof hour === 'object' && hour.event_id) {
            router.get('/event/' + hour.event_id + "/" + getDateFromISO(hour.date).toSQL())
        }
    }

</script>

<style scoped>
    .calendar {
        display: grid;

        grid-auto-flow: column;
        grid-template-columns: 6rem repeat(auto-fit, minmax(13rem, 1fr));
    }

    .calendar-cell {
        border: 1px solid #fff;
        padding: 1rem;
        display: flex;
    }

    .unbookable-slot {
        background: repeating-linear-gradient(
            45deg,
            #fecaca,
            #fecaca 5px,
            rgb(243, 244, 246) 5px,
            rgb(243, 244, 246) 10px
        );
    }
</style>
